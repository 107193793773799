/* Copyright 1999-2021. Plesk International GmbH. All rights reserved. */

.page {
    min-height: 100vh;
}

.header {
    margin-bottom: 24px;
    font-size: 16px;
}

.side {
    display: flex;
    flex-direction: column;
    flex: 0 1 442px;
    text-align: center;
    background: #38475a url("/error_docs/images/server-error.svg") 50% 30% no-repeat;
    background-size: 100% auto;
}

.sideMaintenance {
    padding-bottom: 6px;
    background-image: none;
    background-color: #f0d457;
    box-shadow: inset 0 -6px 0 #797d80;
}

.sideImg {
    max-width: 80%;
}

.sideTop {
    display: none;
}

.sideMiddle {
    margin: auto;
    font-size: 24px;
    font-weight: 100;
    letter-spacing: 2px;
    color: rgba(255, 255,255, 0.8);
}

.sideMiddleImg {
    margin-top: 20px;
    margin-bottom: 5px;
    width: 130px;
}

.sideMiddle b {
    font-weight: 300;
}

.sideBottom {
    margin-top: 20px;
    flex: none;
}

.main {
    box-sizing: border-box;
    padding: 32px 24px;
}

.description {
    margin-bottom: 32px;
}

.actions {
    margin: 32px 0 16px;
}

.action {
    margin: 0 16px 16px 0;
}

.footer {
    margin-top: auto;
    padding-top: 32px;
    border-top: 1px solid #ececec;
}

.footerLinks {
    display: inline-block;
    margin-right: -20px;
    text-align: center;
}

.footerLink {
    display: inline-block;
    vertical-align: top;
    text-align: center;
    min-width: 116px;
    margin: 0 20px 20px 0;
}

.footerLinkText {
    display: block;
    margin-top: 4px;
}

@media (max-width: 769px) {
    .side {
        min-height: 150px;
        background-size: cover;
        background-position: 0 40%;
    }
}

@media (min-width: 768px) {
    .page {
        display: flex;
    }

    .side {
        flex: 0 1 300px;
        min-width: 300px;
    }

    .sideTop {
        display: block;
        margin-bottom: auto;
    }

    .main {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 100vh;
    }
}

@media (min-width: 1300px) {
    .side {
        flex: 0 1 442px;
        min-width: 442px;
    }
}
